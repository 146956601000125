import React, { useState } from 'react';
import './popup.css';
import linked from './LinkedIn_icon.svg';
import email from './mail.png';
import call from './call.png';

function ContactInfoPopup() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="contact" onClick={togglePopup}>Contact Us</button>
      {isOpen && (
        <div className="popup">
          <div className="popup-inner">
            <h3>Contact Us</h3>
            <div className="contact-info">
            <a target="_blank" href="https://www.linkedin.com/company/onguard-security-consulting/"><img src={linked} className="social" alt="linkedin" /></a>
            <a href={'mailto:info@onguard-security.com'}><img src={email} className="social" alt="email" /></a>
            <a href={'tel:704-443-8637'}><img src={call} className="social" alt="call" /></a>
            </div>
            <button onClick={togglePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactInfoPopup;