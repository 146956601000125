import logo from './icon.jpg';
import './App.css';
import ContactInfoPopup from './components/ContactInfoPopup.js'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-slogan">
            Stay On Guard
        </p>
        
        <p className="App-taglines">
          OnGuard Security Consulting aims to provide cyber security advisory and consulting services to small and medium businesses in the Charlotte Metro Area.
        </p>
        <p className="App-taglines">
          Your business is your livelihood, stay on guard and protect your business and your customers.
        </p>
        <ContactInfoPopup/>
      </header>
    </div>
  );
}

export default App;
